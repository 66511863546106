import React, { ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';
import './style.css';

interface CustomTooltipProps {
  id: string;
  children: ReactNode;
}
export const CustomTooltip = ({ id, children }: CustomTooltipProps) => {
  return (
    <Tooltip id={id} className="custom-tooltip" opacity={0.8}>
      {children}
    </Tooltip>
  );
};
