import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyDetails, Shops } from '../models/Company';

export interface AuthState {
  accessToken: string | undefined;
  displayName: string | undefined;
  email: string | undefined;
}

const initialState: CompanyDetails = {
  id: '',
  stripeAccountStatus: '',
  name: '',
  logo: '',
  status: '',
  role: '',
  stripeAccountId: '',
  category: '',
  description: '',
  vatNumber: '',
  website: '',
  shops: [],
  users: [],
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    addNewShop: (state: CompanyDetails, action: PayloadAction<{ shop: Shops }>) => {
      state.shops.push(action.payload.shop);
    },
    updateLogo: (state: CompanyDetails, action: PayloadAction<{ logo: string }>) => {
      return {
        ...state,
        logo: action.payload.logo,
      };
    },
    updateShopName: (state: CompanyDetails, action: PayloadAction<{ shopId: string; newName: string }>) => {
      return {
        ...state,
        shops: state.shops.map((shop) =>
          shop.id === action.payload.shopId ? { ...shop, name: action.payload.newName } : shop
        ),
      };
    },
    setCompanyDetails: (state: CompanyDetails, action: PayloadAction<{ companyDetails: CompanyDetails }>) => {
      return {
        ...state,
        id: action.payload.companyDetails.id,
        stripeAccountStatus: action.payload.companyDetails.stripeAccountStatus,
        name: action.payload.companyDetails.name,
        logo: action.payload.companyDetails.logo,
        status: action.payload.companyDetails.status,
        role: action.payload.companyDetails.role,
        stripeAccountId: action.payload.companyDetails.stripeAccountId,
        category: action.payload.companyDetails.category,
        description: action.payload.companyDetails.description,
        vatNumber: action.payload.companyDetails.vatNumber,
        website: action.payload.companyDetails.website,
        shops: action.payload.companyDetails.shops,
        users: action.payload.companyDetails.users,
      };
    },
  },
});

export const { setCompanyDetails, addNewShop, updateLogo, updateShopName } = companySlice.actions;
export default companySlice.reducer;
