import React from 'react';
import AuthInProgress from './AuthInProgress';
import AuthRequired from './AuthRequired';
import { ReactNode } from 'react';
import { useAuth } from 'oidc-react';

interface AuthRequiredPageProps {
  children?: ReactNode;
}

export default function AuthRequiredPage({ children }: AuthRequiredPageProps) {
  const { isLoading, userData } = useAuth();
  const authStyle: React.CSSProperties = {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F0F9D7',
    textAlign: 'center',
  };

  if (userData) return <>{children}</>;
  else {
    if (isLoading)
      return (
        <div style={authStyle}>
          <AuthInProgress />
        </div>
      );
    else
      return (
        <div style={authStyle}>
          <AuthRequired />
        </div>
      );
  }
}
