import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import AuthProvider from './context/AuthProvider';
import StoreProvider from './context/StoreProvider';
import HomePage from './page/HomePage';
import DashboardPage from './page/DashboardPage';
import ShopDetailsPage from './page/ShopDetailsPage';
import GiftCardPage from './page/GiftCardPage';
import Layout from './components/Layout/Layout';
import CompanyLayout from './components/Layout/CompanyLayout';
import ConnectAccountBankPage from './page/ConnectAccountBankPage';
import BankAccountConnectedPage from './page/BankAccountConnectedPage';
import AddShopPageTemp from './page/AddShopPageTemp';
import AuthRequiredPage from './components/Auth/AuthRequiredPage';
import ErrorAuthorizationPage from './page/ErrorAuthorizationPage';
import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import './App.css';
import './style/font.css';
import 'react-loading-skeleton/dist/skeleton.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <StoreProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthRequiredPage>
                    <Layout />
                  </AuthRequiredPage>
                }
              >
                <Route path="/" element={<HomePage />} />
                <Route path="/company/:companyId/stripe/account" element={<ConnectAccountBankPage />} />
                <Route path="/company/:companyId/stripe/account/:accountId" element={<BankAccountConnectedPage />} />
                <Route path="/company/:companyId" element={<CompanyLayout />}>
                  <Route path="/company/:companyId/dashboard" element={<DashboardPage />} />
                  <Route path="/company/:companyId/shop/:shopId" element={<ShopDetailsPage />} />
                  <Route path="/company/:companyId/giftcard" element={<GiftCardPage />} />
                  <Route path="/company/:companyId/add-shop" element={<AddShopPageTemp />} />
                </Route>
                <Route path="/error-auth" element={<ErrorAuthorizationPage />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </StoreProvider>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer theme="colored" position="bottom-right" autoClose={4000} transition={Slide} />
    </div>
  );
}

export default App;
