import React from 'react';
import { CustomCard } from '../CustomCard';
import useCheckMobileResolution from '../../hook/useCheckMobileResolution';
import './splash_screen.css';

export default function AuthInProgress() {
  const isMobile = useCheckMobileResolution();

  return (
    <div className="splash-screen-container">
      <CustomCard padding="50px">
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
          }}
        >
          <div className="splash-screen-circle-tick"></div>
          <div className="login-title-font">Accesso in corso</div>
        </div>
      </CustomCard>
    </div>
  );
}
