import React, { ChangeEvent, ReactNode } from 'react';
import { CustomTooltip } from '../../CustomTooltip';
import useCheckMobileResolution from '../../../hook/useCheckMobileResolution';
import Input from '../../Input';
import saveIcon from '../../../assets/images/save-icon.svg';
import './style.css';

interface PageLayoutProps {
  titlePageLogo?: string;
  hanldeTitlePageLogoClick?: () => void;
  titlePage: string;
  onTitlePageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  showTitlePageSaveButton?: boolean;
  titlePageUpdate?: () => void;
  subtitlePage?: string;
  children: ReactNode;
}

export default function PageLayout({
  titlePageLogo,
  hanldeTitlePageLogoClick,
  titlePage,
  onTitlePageChange,
  showTitlePageSaveButton,
  titlePageUpdate,
  subtitlePage,
  children,
}: PageLayoutProps) {
  const isMobile = useCheckMobileResolution();

  return (
    <div className="container" style={{ fontSize: '14px' }}>
      {titlePageLogo ? (
        <div
          style={
            isMobile
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  textAlign: 'center',
                }
              : { display: 'flex' }
          }
        >
          <img className="title-image" alt="titlePageLogo" src={titlePageLogo} onClick={hanldeTitlePageLogoClick} />
          <div>
            <div className="title">{titlePage}</div>
            {subtitlePage ? <div className="sub-title">{subtitlePage}</div> : null}
          </div>
        </div>
      ) : (
        <>
          {onTitlePageChange && titlePageUpdate ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
              <Input
                styleContainer={{ marginBottom: '0px' }}
                text={titlePage}
                isTextTitle
                placeholder="Nome attività"
                handleChange={onTitlePageChange}
              />
              {showTitlePageSaveButton ? (
                <>
                  <div style={{ width: '50px', padding: '10px' }}>
                    <img
                      data-tooltip-id="save-icon-element"
                      style={{ cursor: 'pointer' }}
                      alt="saveIcon"
                      src={saveIcon}
                      onClick={titlePageUpdate}
                    />
                  </div>
                  <CustomTooltip id="save-icon-element">Salva nuovo nome</CustomTooltip>
                </>
              ) : null}
            </div>
          ) : (
            <div className="title">{titlePage}</div>
          )}
          {subtitlePage ? <div className="sub-title">{subtitlePage}</div> : null}
        </>
      )}
      {children}
    </div>
  );
}
