import React from 'react';
import useCheckMobileResolution from '../../hook/useCheckMobileResolution';
import { uploadStripeAccountLinkUrlAPI } from '../../services/companiesApi';
import { CreateStripeAccountLinkResult } from '../../models/Company';
import { toastSuccess, toastWarning } from '../../utils/toastMethod';

interface StripeAccountStatusInfoProps {
  companyId: string;
  accountId: string;
}
export default function StripeAccountStatusInfo({ companyId, accountId }: StripeAccountStatusInfoProps) {
  const isMobile = useCheckMobileResolution();

  const handleCompleteProfile = (id: string) => {
    uploadStripeAccountLinkUrlAPI(companyId, id, {
      refreshUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/dashboard?stripe-updated=true`,
      returnUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/dashboard?stripe-updated=true`,
    })
      .then((redirectUrl: CreateStripeAccountLinkResult) => {
        if (redirectUrl) {
          toastSuccess('Stai per essere reindirizzato su Stripe per completare il profilo con i dati mancanti.');
          window.location.href = redirectUrl.url;
        }
      })
      .catch(() =>
        toastWarning("Problemi durante il completamento del profilo attività. Riprova o contatta l'assistenza.")
      );
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#FFF6E7',
        borderRadius: '10px',
        border: '1px solid #FFC04E',
        padding: '20px',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        marginBottom: '20px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginRight: 'auto' }}>
        <div style={{ fontWeight: '600' }}>Il tuo profilo Stripe risulta incompleto</div>
        <div style={{ marginTop: '3px' }}>
          Verrai reindirizzato su Stripe. Segui la procedura guidata per completare le parti mancanti.
        </div>
      </div>
      <div
        style={{
          width: '215px',
          marginTop: isMobile ? '20px' : 'auto',
          marginBottom: 'auto',
          marginLeft: !isMobile ? '20px' : '',
        }}
      >
        <button type="button" className="btn btn-primary" onClick={() => handleCompleteProfile(accountId)}>
          Completa profilo Stripe
        </button>
      </div>
    </div>
  );
}
