import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FileRejection, useDropzone } from 'react-dropzone';
import { UploadingImage } from '../../../models/Utils';
import { CustomTooltip } from '../../CustomTooltip';
import { toastInfo, toastWarning } from '../../../utils/toastMethod';
import { uploadCompanyLogoAPI } from '../../../services/companiesApi';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateLogo } from '../../../store/companySlice';
import uploadImageIcon from '../../../assets/images/upload-image-icon.svg';
import uploadProgressIcon from '../../../assets/images/upload-progress-icon.svg';
import './style.css';

interface UpdateCompanyLogoProps {
  show: boolean;
  setShow: (value: boolean) => void;
  actualLogo: string;
}

export default function UpdateCompanyLogo({ show, setShow, actualLogo }: UpdateCompanyLogoProps) {
  const dispatch = useDispatch();
  const { companyId } = useParams<string>();
  const [logoPreview, setLogoPreview] = useState<UploadingImage>({ preview: actualLogo, uploaded: true });

  const handleUpdateLogo = async (files: File[]) => {
    const file: File = files[0];
    if (file === null || file === undefined) return;

    const newLogo: UploadingImage = {
      file: file,
      preview: URL.createObjectURL(file),
      uploaded: false,
    };
    setLogoPreview(newLogo);

    if (newLogo.file !== undefined) {
      const formData = new FormData();
      formData.append('logo', newLogo.file);

      await uploadCompanyLogoAPI(companyId, formData)
        .then(() => {
          setLogoPreview((prevLogo) => ({ ...prevLogo, uploaded: true }));
          dispatch(updateLogo({ logo: URL.createObjectURL(file) }));
        })
        .catch(() => {
          toastWarning("Errore durante l'aggiornamento del logo. Riprova!");
          setLogoPreview((prevLogo) => ({ ...prevLogo, preview: actualLogo, uploaded: true }));
        });
    }
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      if (file.errors.some((error) => error.code === 'file-too-large')) {
        toastInfo(`${file.file.name} è troppo grande. La dimensione massima consentita è 5 MB.`);
      } else {
        toastInfo(`${file.file.name} non è un file valido. Assicurati di caricare solo immagini.`);
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    maxSize: 5 * 1024 * 1024,
    onDrop: handleUpdateLogo,
    onDropRejected,
  });

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header>
        <Modal.Title>Aggiorna il logo della tua organizzazione</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dropzone-logo-custom-box">
          <div {...getRootProps()} className="dropzone-logo-custom-area">
            <input {...getInputProps()} />
            {logoPreview.preview.length === 0 ? (
              <div className="dropzone-logo-text-box">
                <img alt="uploadImageIcon" src={uploadImageIcon} style={{ marginRight: '10px' }} />
                {isDragActive ? "Rilascia l'immagine" : "Clicca qui o trascina l'immagine"}
              </div>
            ) : (
              <div className="logo-wrapper">
                <img src={logoPreview.preview} alt="preview" className="logo-preview" />
                {!logoPreview.uploaded ? (
                  <img className="logo-loading-spinner" src={uploadProgressIcon} alt="uploadProgressIcon" />
                ) : (
                  <>
                    <img
                      data-tooltip-id="update-logo-element"
                      className="logo-upload-icon"
                      src={uploadImageIcon}
                      alt="uploadImageIcon"
                    />
                    <CustomTooltip id="update-logo-element">Aggiorna il logo</CustomTooltip>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={() => setShow(false)}>
          Chiudi
        </button>
      </Modal.Footer>
    </Modal>
  );
}
