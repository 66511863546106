import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import HeaderBar from '../HeaderBar';
import './style.css';

export default function Layout() {
  return (
    <>
      <div className="layout-container">
        <header className="headerbar">
          <HeaderBar />
        </header>

        <main className="main-content">
          <Outlet />
        </main>

        <footer className="footer">
          <Footer />
        </footer>
      </div>
    </>
  );
}
