import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hook/useReduxHooks';
import { RootState } from '../../../store/store';
import useCheckMobileResolution from '../../../hook/useCheckMobileResolution';
import dashboardIcon from '../../../assets/images/sidebar/dashboard-icon.svg';
import shopsIcon from '../../../assets/images/sidebar/shops-icon.svg';
import giftcardIcon from '../../../assets/images/sidebar/giftcard-icon.svg';
import expandMoreArrowIcon from '../../../assets/images/sidebar/expand_more_arrow.svg';
import addShopIcon from '../../../assets/images/add-icon.svg';
import './style.css';

export default function SideBar() {
  const { companyId } = useParams<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const shops = useAppSelector((state: RootState) => state.company.shops);
  const [isOpen, setIsOpen] = useState(true);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const isMobile = useCheckMobileResolution();

  useEffect(() => {
    setIsOpen(!isMobile);
  }, [isMobile]);

  const toggleSidebar = () => {
    if (isMobile) setIsOpen(!isOpen);
    setIsSubMenuOpen(false);
  };

  const handleSubMenuItemClick = () => {
    if (isMobile) setIsOpen(!isOpen);
  };

  const toggleSubMenu = () => {
    if (isSubMenuOpen === false) navigate(`/company/${companyId}/shop/${shops[0].id}`);
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <>
      {isMobile ? (
        <button className="burger-button" onClick={toggleSidebar}>
          &#9776;
        </button>
      ) : null}
      <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
        {isMobile ? (
          <button className="close-button" onClick={toggleSidebar}>
            &times;
          </button>
        ) : null}
        <ul>
          <li className={location.pathname === `/company/${companyId}/dashboard` ? 'selected' : ''}>
            <Link to={`/company/${companyId}/dashboard`} onClick={toggleSidebar}>
              <div className="link-text-image">
                <img alt="dashboardIcon" src={dashboardIcon} style={{ marginRight: '10px' }} />
                Dashboard
              </div>
            </Link>
          </li>
          <li
            className={location.pathname.includes(`/company/${companyId}/shop`) ? 'selected' : ''}
            style={{ backgroundColor: 'white' }}
          >
            <div onClick={toggleSubMenu} className={`menu-item ${isSubMenuOpen ? 'open' : ''}`}>
              <img alt="shopsIcon" src={shopsIcon} style={{ marginRight: '10px' }} />
              <div style={{ marginRight: 'auto' }}>Negozi</div>
              <img
                alt="expandMoreArrowIcon"
                src={expandMoreArrowIcon}
                className={`arrow ${isSubMenuOpen ? 'open' : ''}`}
              />
            </div>
            {isSubMenuOpen && (
              <ul className="submenu">
                {shops?.map((shop, index) => (
                  <li
                    className={location.pathname === `/company/${companyId}/shop/${shop.id}` ? 'sub-item-selected' : ''}
                    style={{ paddingLeft: '30px' }}
                    key={index}
                    onClick={handleSubMenuItemClick}
                  >
                    <Link style={{ fontWeight: '400' }} to={`/company/${companyId}/shop/${shop.id}`}>
                      {shop.name}
                    </Link>
                  </li>
                ))}
                <li
                  className={location.pathname === `/company/${companyId}/add-shop` ? 'sub-item-selected' : ''}
                  style={{ paddingLeft: '30px' }}
                  onClick={handleSubMenuItemClick}
                >
                  <Link style={{ fontWeight: '400' }} to={`/company/${companyId}/add-shop`}>
                    <img alt="addShopIcon" src={addShopIcon} style={{ marginRight: '5px', width: '15px' }} />
                    Aggiungi negozio
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className={location.pathname === `/company/${companyId}/giftcard` ? 'selected' : ''}>
            <Link to={`/company/${companyId}/giftcard`} onClick={toggleSidebar}>
              <div className="link-text-image">
                <img alt="giftcardIcon" src={giftcardIcon} style={{ marginRight: '10px' }} />
                Gift Card
              </div>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
