import React from 'react';
import './style.css';
import Skeleton from 'react-loading-skeleton';

export default function TextAreaSkeleton() {
  return (
    <div className="textarea-container">
      <div className="textarea-label">
        <Skeleton />
      </div>
      <Skeleton height={80} />
    </div>
  );
}
