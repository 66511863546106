import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from '../hook/useReduxHooks';
import { RootState } from '../store/store';
import { selectAuth } from '../store/authSlice';

export default function AddShopPageTemp() {
  const navigate = useNavigate();
  const { companyId } = useParams<string>();
  const company = useAppSelector((state: RootState) => state.company);
  const merchant = useAppSelector(selectAuth);
  const [show, setShow] = useState(true);

  const subject = `Richiesta%20informazioni%20da:%20${merchant.displayName}%20di%20${company.name}`;
  const body = `Ciao%20sono%20${merchant.displayName}%20dell'attività%20${company.name}.%20Vorrei%20aggiungere%20il%20seguente%20nuovo%20punto%20vendità%20per%20la%20mia%20attività:%0A- Nome del tuo negozio:%0A- Indirizzo:%0A- Città:`;
  const mailtoLink = `mailto:supporto@buonisulserio.it?subject=${subject}&body=${body}`;

  const handleHide = () => {
    navigate(`/company/${companyId}/dashboard`);
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header>
        <Modal.Title>Aggiungi un nuovo negozio 🛒</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Clicca <a href={mailtoLink}>qui</a> per inviare una email al supporto di Buoni Sul Serio, indicando:
        <ul style={{ marginTop: '5px', marginBottom: '5px' }}>
          <li>Nome del tuo negozio</li>
          <li>Indirizzo</li>
          <li>Città</li>
        </ul>
        del nuovo negozio che vorresti aggiungere alla tua attività.
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={handleHide}>
          Chiudi
        </button>
      </Modal.Footer>
    </Modal>
  );
}
