import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../hook/useReduxHooks';
import { RootState } from '../store/store';
import { useSearchParams } from 'react-router-dom';
import { toastInfo, toastSuccess } from '../utils/toastMethod';
import { StripeAccountStatus } from '../models/Company';
import PageLayout from '../components/Layout/PageLayout';
import ReedemGiftCards from '../components/DashboardPage/ReedemGiftCards';
import GiftCardsHistory from '../components/DashboardPage/GiftCardsHistory';
import UpdateCompanyLogo from '../components/DashboardPage/UpdateCompanyLogo';
import StripeAccountStatusInfo from '../components/DashboardPage/StripeAccountStatusInfo';

export default function DashboardPage() {
  const companyDetails = useAppSelector((state: RootState) => state.company);
  const [searchParams] = useSearchParams();
  const onboarding = searchParams.get('onboarding');
  const stripeUpdated = searchParams.get('stripe-updated');
  const [showUpdateCompanyLogo, setShowUpdateCompanyLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    if (onboarding === 'true') {
      if (companyDetails.stripeAccountStatus === StripeAccountStatus.COMPLETE)
        toastSuccess('Complimenti hai completato la procedura di registrazione.');
      else toastInfo('Attenzione! Il tuo profilo Stripe risulta incompleto. Completa con i dati mancanti!');
    }
    if (stripeUpdated === 'true')
      if (companyDetails.stripeAccountStatus !== StripeAccountStatus.COMPLETE)
        toastInfo('Attenzione! Il tuo profilo Stripe risulta incompleto. Completa con i dati mancanti!');
      else toastSuccess('Profilo Stripe aggiornato.');
  }, [onboarding, stripeUpdated, companyDetails.stripeAccountStatus]);

  useEffect(() => {
    if (companyDetails.logo === null) setLogoUrl('https://freeloop.it/wp-content/uploads/2022/07/03300.png');

    if (companyDetails.logo.includes(companyDetails.id)) {
      const currentTimestamp = new Date().getTime();
      setLogoUrl(`${companyDetails.logo}?cacheBuster=${currentTimestamp}`);
    } else setLogoUrl(companyDetails.logo);
  }, [companyDetails.id, companyDetails.logo]);

  const hanldeTitlePageLogoClick = () => setShowUpdateCompanyLogo(true);

  return (
    <PageLayout
      titlePageLogo={logoUrl}
      hanldeTitlePageLogoClick={hanldeTitlePageLogoClick}
      titlePage={companyDetails.name}
      subtitlePage="Benvenuto nella tua dashboard. Qui trovi le informazioni relative ai tuoi negozi."
    >
      {companyDetails.stripeAccountStatus === StripeAccountStatus.COMPLETE ? (
        <>
          <ReedemGiftCards />
          <GiftCardsHistory />
        </>
      ) : (
        <StripeAccountStatusInfo companyId={companyDetails.id} accountId={companyDetails.stripeAccountId} />
      )}

      {showUpdateCompanyLogo ? (
        <UpdateCompanyLogo show={showUpdateCompanyLogo} setShow={setShowUpdateCompanyLogo} actualLogo={logoUrl} />
      ) : null}
    </PageLayout>
  );
}
