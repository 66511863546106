export enum StripeAccountStatus {
  COMPLETE = 'COMPLETE',
  RESTRICTED = 'RESTRICTED',
  PENDING = 'PENDING',
  INCOMPLETE = 'INCOMPLETE',
}

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export type Shops = {
  id: string;
  name: string;
};

type User = {
  id: string;
  email: string;
  role: string;
};

export type Company = {
  id: string;
  name: string;
  logo: string;
  status: string;
  role: string;
};

export type CompanyDetails = Company & {
  stripeAccountId: string;
  stripeAccountStatus: string;
  category: string;
  description: string;
  vatNumber: string;
  website: string;
  shops: Shops[];
  users: User[];
};

export type CreateStripeAccountResult = {
  companyId: string;
  accountId: string;
};

export type CreateStripeAccountLinkResult = CreateStripeAccountResult & {
  url: string;
};

export type PostStripeAccountLinkUrl = {
  returnUrl: string;
  refreshUrl: string;
};

export type StripeAccountOnboardingResult = CreateStripeAccountResult & {
  isOnboarded: boolean;
};

export type UploadLogoResult = {
  url: string;
};
