import React, { useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { toastInfo, toastWarning } from '../../utils/toastMethod';
import { deleteImageAPI, uploadShopImageAPI } from '../../services/shopsApi';
import { Photo } from '../../models/Shop';
import { UploadingImage } from '../../models/Utils';
import uploadImageIcon from '../../assets/images/upload-image-icon.svg';
import uploadProgressIcon from '../../assets/images/upload-progress-icon.svg';
import deleteIcon from '../../assets/images/delete-icon.svg';
import addImageIcon from '../../assets/images/add-icon.svg';
import './style.css';

const MAX_IMAGES_ALLOWED = 5;

type ImagePlaceholderData = {
  id: number;
};

interface DropzoneProps {
  title?: string;
  companyId: string | undefined;
  shopId: string | undefined;
  photos: Photo[];
}

export default function DropzoneCustom({ title, companyId, shopId, photos }: DropzoneProps) {
  const [images, setImages] = useState<UploadingImage[]>([]);
  const [imagePlaceholderArray, setImagePlaceholderArray] = useState<ImagePlaceholderData[]>([]);

  useEffect(() => {
    // Initialize Image and Image placeholder
    setImages([]);
    const initialImages: UploadingImage[] = photos.map((photo) => ({
      photoId: photo.id,
      preview: photo.url,
      uploaded: true,
    }));
    setImages((prevImages) => [...prevImages, ...initialImages]);

    const initialiImagePlaceholder: ImagePlaceholderData[] = [];
    for (let i = 0; i < MAX_IMAGES_ALLOWED - photos.length; i++) {
      initialiImagePlaceholder.push({ id: i });
    }
    setImagePlaceholderArray(initialiImagePlaceholder);
  }, [photos]);

  const handleRemoveImage = async (photoId: string | undefined, event: React.MouseEvent) => {
    event.stopPropagation();
    await deleteImageAPI(companyId, shopId, photoId)
      .then(() => {
        setImages((prevImages) => prevImages.filter((image) => image.photoId !== photoId));
        setImagePlaceholderArray((prevPlaceholder) => [...prevPlaceholder, { id: prevPlaceholder.length }]);
      })
      .catch(() => toastWarning('Qualcosa non è andato a buon fine. Riprova!'));
  };

  const onDropAccepted = async (uploadedImages: File[]) => {
    if (imagePlaceholderArray.length - uploadedImages.length < 0 || images.length === MAX_IMAGES_ALLOWED) {
      toastInfo('Puoi caricare fino a 5 immagini.');
      return;
    }

    for (let i = 0; i < uploadedImages.length; i++) {
      setImagePlaceholderArray((prevPlaceholder) =>
        prevPlaceholder.filter((placeholder) => placeholder.id !== prevPlaceholder.length - 1)
      );
    }

    const imagesToUpload: UploadingImage[] = uploadedImages.map((image) => ({
      file: image,
      preview: URL.createObjectURL(image),
      uploaded: false,
    }));
    setImages((prevImages) => [...prevImages, ...imagesToUpload]);

    imagesToUpload.forEach(async (imageToUpload) => {
      if (imageToUpload.file !== undefined) {
        const formData = new FormData();
        formData.append('photo', imageToUpload.file);

        await uploadShopImageAPI(companyId, shopId, formData)
          .then((result: Photo) => {
            setImages((prevImages) =>
              prevImages.map((image) =>
                image === imageToUpload ? { ...image, photoId: result.id, uploaded: true } : image
              )
            );
          })
          .catch(() => {
            toastWarning("Errore nel carimento dell'immagine " + imageToUpload.file?.name + '. Riprova!');
            setImages((prevImages) => prevImages.filter((image) => image !== imageToUpload));
            setImagePlaceholderArray((prevPlaceholder) => [...prevPlaceholder, { id: prevPlaceholder.length }]);
          });
      }
    });
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      if (file.errors.some((error) => error.code === 'file-too-large')) {
        toastInfo(`${file.file.name} è troppo grande. La dimensione massima consentita è 5 MB.`);
      } else {
        toastInfo(`${file.file.name} non è un file valido. Assicurati di caricare solo immagini.`);
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    maxSize: 5 * 1024 * 1024,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <div className="dropzone-custom-container">
      <label className="dropzone-custom-label">{title}</label>
      <div className="dropzone-custom-box">
        <div {...getRootProps()} className="dropzone-custom-area">
          <input {...getInputProps()} />
          {images.length === 0 ? (
            <div className="dropzone-custom-text-box">
              <img alt="uploadImageIcon" src={uploadImageIcon} style={{ marginRight: '10px' }} />
              {isDragActive ? "Rilascia l'immagine" : "Clicca qui o trascina l'immagine"}
            </div>
          ) : (
            <div className="image-gallery">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="image-wrapper"
                  style={
                    imagePlaceholderArray.length > 0
                      ? { marginRight: '30px' }
                      : images.length - 1 === index
                        ? {}
                        : { marginRight: '30px' }
                  }
                >
                  <img src={image.preview} alt="preview" className="image-preview" />
                  {!image.uploaded ? (
                    <img className="loading-spinner" src={uploadProgressIcon} alt="uploadProgressIcon" />
                  ) : (
                    <img
                      className="delete-icon"
                      src={deleteIcon}
                      alt="deleteIcon"
                      onClick={(event) => handleRemoveImage(image.photoId, event)}
                    />
                  )}
                </div>
              ))}
              {imagePlaceholderArray.map((_, index) => (
                <div
                  key={index}
                  className="image-wrapper"
                  style={imagePlaceholderArray.length - 1 === index ? {} : { marginRight: '30px' }}
                >
                  <div className="image-placeholder">
                    <img src={addImageIcon} alt="addImageIcon" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
