import React, { useEffect, useState } from 'react';
import useCheckMobileResolution from '../../../hook/useCheckMobileResolution';
import Skeleton from 'react-loading-skeleton';
import './style.css';

export default function SideBarSkeleton() {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = useCheckMobileResolution();

  useEffect(() => {
    setIsOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
        <ul style={{ paddingLeft: '12px', paddingRight: '12px' }}>
          <li style={{ marginBottom: '10px' }}>
            <Skeleton height={30} />
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Skeleton height={30} />
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Skeleton height={30} />
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Skeleton height={30} />
          </li>
        </ul>
      </nav>
    </>
  );
}
