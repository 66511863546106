import React, { useState } from 'react';
import useCheckMobileResolution from '../../hook/useCheckMobileResolution';
import { uploadStripeAccountLinkUrlAPI } from '../../services/companiesApi';
import { useParams } from 'react-router-dom';
import { CreateStripeAccountLinkResult } from '../../models/Company';
import { toastSuccess, toastWarning } from '../../utils/toastMethod';
import { useAppSelector } from '../../hook/useReduxHooks';
import { RootState } from '../../store/store';

export default function ReedemGiftCards() {
  const { companyId } = useParams<string>();
  const stipeAccountId = useAppSelector((state: RootState) => state.company.stripeAccountId);
  const isMobile = useCheckMobileResolution();
  /*MOCK*/
  const [redeemValue, setRedeemValue] = useState(0);
  /*END MOCK*/

  const handleReedem = () => {
    setRedeemValue(0);
  };

  const handleUpdateStripeProfile = () => {
    uploadStripeAccountLinkUrlAPI(companyId, stipeAccountId, {
      refreshUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/dashboard?stripe-updated=true`,
      returnUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/dashboard?stripe-updated=true`,
    })
      .then((redirectUrl: CreateStripeAccountLinkResult) => {
        if (redirectUrl) {
          toastSuccess('Stai per essere reindirizzato su Stripe.');
          window.location.href = redirectUrl.url;
        }
      })
      .catch(() =>
        toastWarning("Problemi durante l'aggiornamento del tuo profilo Stripe. Riprova o contatta l'assistenza.")
      );
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#F0F9D7',
        borderRadius: '10px',
        border: '1px solid #D4D4D4',
        padding: '20px',
        display: 'flex',
        alignItems: isMobile ? 'center' : '',
        flexDirection: isMobile ? 'column' : 'row',
        marginBottom: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '220px',
          height: '65px',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          marginRight: '20px',
          fontWeight: '700',
          fontSize: '32px',
        }}
      >
        {redeemValue}€
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginRight: 'auto',
          marginTop: isMobile ? '20px' : '',
        }}
      >
        <div style={{ fontWeight: '600' }}>Riscuoti i tuoi buoni</div>
        <div>{"Premi sul pulsante 'Riscuoti' per trasferire i soldi sul tuo conto corrente."}</div>
      </div>
      <div
        style={{
          marginTop: isMobile ? '20px' : 'auto',
          marginLeft: '10px',
          display: 'flex',
          flexDirection: 'column',
          width: '215px',
        }}
      >
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: '10px' }}
          onClick={handleReedem}
          disabled={redeemValue > 0 ? false : true}
        >
          Riscuoti
        </button>
        <button type="button" className="btn btn-primary" onClick={handleUpdateStripeProfile}>
          Aggiorna profilo Stripe
        </button>
      </div>
    </div>
  );
}
