import React from 'react';
import { logout } from '../store/authSlice';
import { useAuth } from 'oidc-react';
import { useDispatch } from 'react-redux';
import PageLayout from '../components/Layout/PageLayout';

export default function ErrorAuthorizationPage() {
  const dispatch = useDispatch();
  const auth = useAuth();

  const handleRedirect = () => {
    const backofficeUrl = process.env.REACT_APP_BACKOFFICE_URL;
    if (backofficeUrl !== undefined) window.location.href = backofficeUrl;
  };

  return (
    <PageLayout titlePage="Attenzione!" subtitlePage="Purtroppo non hai i permessi per accedere a questa pagina.">
      <div style={{ marginBottom: '10px' }}>Forse volevi accedere al Backoffice?</div>
      <button type="button" className="btn btn-primary" style={{ marginBottom: '20px' }} onClick={handleRedirect}>
        Vai al Backoffice
      </button>
      <div style={{ marginBottom: '10px' }}>Altrimenti premi semplicemente Log Out</div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={async () => {
          const idToken = auth.userData?.id_token;
          await auth.signOut();
          await auth.signOutRedirect({
            id_token_hint: idToken,
          });
          dispatch(logout());
        }}
      >
        Log Out
      </button>
    </PageLayout>
  );
}
