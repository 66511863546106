import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from './authSlice';
import companyReducer from './companySlice';
import navigationReducer from './navigationSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    company: companyReducer,
    navigation: navigationReducer,
  },
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
