import React from 'react';
import PageLayout from '../components/Layout/PageLayout';

export default function GiftCardPage() {
  return (
    <PageLayout titlePage="Gift Card">
      <>In arrivo...</>
    </PageLayout>
  );
}
