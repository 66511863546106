import React from 'react';

/*MOCK*/
/* const giftCardsHistory = [
  {
    date: '11 Giugno 2024, 08:30',
    giftCardCode: 'FRG-235454680',
    giftCardValue: '€14',
  },
  {
    date: '19 Giugno 2024, 08:30',
    giftCardCode: 'FRG-235454999',
    giftCardValue: '€200',
  },
  {
    date: '29 Giugno 2024, 08:30',
    giftCardCode: 'FRG-235454852',
    giftCardValue: '€1',
  },
]; */
/*END MOCK*/

export default function GiftCardsHistory() {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        border: '1px solid #D4D4D4',
        padding: '20px',
      }}
    >
      <label style={{ color: '#004159', fontWeight: '600', marginBottom: '20px' }}>Gift Cards Riscosse</label>
      {/*       {giftCardsHistory.map((giftCard, index) => {
        return (
          <div key={index} style={{ width: '100%', marginBottom: '10px', display: 'flex' }}>
            <div style={{ marginRight: 'auto' }}>{giftCard.date}</div>
            <div>
              {giftCard.giftCardCode} - {giftCard.giftCardValue}
            </div>
          </div>
        );
      })} */}
      <div>Non ci sono Gift Card riscosse al momento.</div>
    </div>
  );
}
