import React, { useState, useEffect, ChangeEvent } from 'react';
import { toastSuccess, toastWarning } from '../utils/toastMethod';
import { useParams } from 'react-router-dom';
import { getShopDetailsAPI, updateShopAPI, updateShopNameAPI } from '../services/shopsApi';
import { ShopDetails } from '../models/Shop';
import { DashboardSkeleton } from '../components/Skeleton/DashboardSkeleton';
import { useDispatch } from 'react-redux';
import { updateShopName } from '../store/companySlice';
import PageLayout from '../components/Layout/PageLayout';
import TextArea from '../components/TextArea';
import Input from '../components/Input';
import DropzoneCustom from '../components/DropzoneCustom';
import PageLayoutSkeleton from '../components/Layout/PageLayout/skeleton';

export default function ShopDetailsPage() {
  const dispatch = useDispatch();
  const { companyId } = useParams<string>();
  const { shopId } = useParams<string>();
  const [shopDetails, setShopDetails] = useState<ShopDetails>();
  const [showTitlePageSaveButton, setShowTitlePageSaveButton] = useState(false);
  const [savedCompanyName, setSavedCompanyName] = useState('');
  const [isShopUpdatable, setIsShopUpdatable] = useState(false);
  const [isWebsiteCorrect, setIsWebsiteCorrect] = useState(true);
  const titleMaxChars = 50;

  useEffect(() => {
    getShopDetailsAPI(companyId, shopId)
      .then((result: ShopDetails) => {
        setShopDetails(result);
        setSavedCompanyName(result.name);
      })
      .catch(() => toastWarning('Errore nel carimento delle informazioni della tuo negozio. Riprova!'));
  }, [companyId, shopId]);

  useEffect(() => {
    if (isShopUpdatable) {
      const timer = setTimeout(() => {
        if (shopDetails?.description || shopDetails?.address || shopDetails?.phoneNumber || shopDetails?.website) {
          updateShopAPI(companyId, shopId, {
            description: shopDetails.description,
            address: shopDetails.address,
            phoneNumber: shopDetails.phoneNumber,
            website: shopDetails.website,
          }).catch(() =>
            toastWarning(
              "Errore nell'aggiornamento delle informazioni del tuo negozio. Verifica che tutti i campi siano corretti e riprova!"
            )
          );
        }
        setIsShopUpdatable(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [
    companyId,
    shopId,
    shopDetails?.description,
    shopDetails?.address,
    shopDetails?.phoneNumber,
    shopDetails?.website,
    isShopUpdatable,
  ]);

  useEffect(() => {
    setShowTitlePageSaveButton(false);
  }, [shopId]);

  const handleTitlePage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > titleMaxChars) return;
    if (shopDetails) {
      setShopDetails({
        ...shopDetails,
        name: event.target.value,
      });
      if (savedCompanyName !== event.target.value) setShowTitlePageSaveButton(true);
      else setShowTitlePageSaveButton(false);
    }
  };

  const titlePageUpdate = () => {
    if (shopDetails !== undefined && companyId !== undefined && shopId !== undefined) {
      updateShopNameAPI(companyId, shopId, {
        name: shopDetails?.name,
      })
        .then(() => {
          setShowTitlePageSaveButton(false);
          setSavedCompanyName(shopDetails.name);
          dispatch(updateShopName({ shopId: shopId, newName: shopDetails.name }));
          toastSuccess('Nome aggiornato.');
        })
        .catch(() => toastWarning('Il nome del tuo negozio non è stato aggiornato correttamente. Riprova!'));
    }
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (shopDetails) {
      setShopDetails({
        ...shopDetails,
        description: event.target.value,
      });
      setIsShopUpdatable(true);
    }
  };

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (shopDetails) {
      setShopDetails({
        ...shopDetails,
        address: event.target.value,
      });
      setIsShopUpdatable(true);
    }
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (shopDetails) {
      setShopDetails({
        ...shopDetails,
        phoneNumber: event.target.value,
      });
      setIsShopUpdatable(true);
    }
  };

  const handleWebsiteChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (shopDetails) {
      setShopDetails({
        ...shopDetails,
        website: event.target.value,
      });
      const urlPattern = /^(?:\s*|https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
      if (event.target.value.length > 0 && !urlPattern.test(event.target.value)) {
        setIsWebsiteCorrect(false);
        return;
      }
      setIsWebsiteCorrect(true);
      setIsShopUpdatable(true);
    }
  };

  return (
    <>
      {shopDetails ? (
        <PageLayout
          titlePage={shopDetails.name}
          onTitlePageChange={handleTitlePage}
          showTitlePageSaveButton={showTitlePageSaveButton}
          titlePageUpdate={titlePageUpdate}
          subtitlePage="Pagina dedicata al tuo negozio. Inserisci o modifica le informazioni mostrate nella vetrina negozi. Questa informazioni sono visibili pubblicamente agli utenti che acquistano una giftcard."
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextArea
              title="Descrizione"
              text={shopDetails.description}
              placeholder="Inserisci la descrizione della tuo negozio"
              handleChange={handleDescriptionChange}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                styleContainer={{ marginRight: '10px' }}
                label="Indirizzo"
                text={shopDetails.address}
                placeholder="Via Ingegner Balduzzi, 10"
                handleChange={handleAddressChange}
              />
              <Input
                label="Città"
                text={shopDetails.zipCode + ', ' + shopDetails.town + ', ' + shopDetails.province}
                placeholder="Clusone, BG"
                isReadOnly
              />
            </div>
            <Input
              label="Numero di telefono"
              text={shopDetails.phoneNumber}
              placeholder="3334455789"
              handleChange={handlePhoneNumberChange}
            />
            <Input
              label="Sito web"
              text={shopDetails.website}
              textError={
                isWebsiteCorrect ? undefined : '* Inserisci un sito web corretto, es: https://buonisulserio.it'
              }
              placeholder="https://www.buonisulserio.it"
              handleChange={handleWebsiteChange}
            />
            <DropzoneCustom
              title="Immagini galleria"
              companyId={companyId}
              shopId={shopId}
              photos={shopDetails.photos}
            />
          </div>
        </PageLayout>
      ) : (
        <PageLayoutSkeleton>
          <DashboardSkeleton />
        </PageLayoutSkeleton>
      )}
    </>
  );
}
