import { AddShop, Photo, UpdateShop, UpdateShopName } from '../models/Shop';
import api from './api';

export async function getShopDetailsAPI(companyId: string | undefined, shopId: string | undefined) {
  return api.get(`/api/dashboard/companies/${companyId}/shops/${shopId}`).then((response) => response.data);
}

export async function updateShopAPI(companyId: string | undefined, shopId: string | undefined, payload: UpdateShop) {
  return new Promise<string>((resolve, reject) => {
    api
      .put(`api/dashboard/companies/${companyId}/shops/${shopId}`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}

export async function updateShopNameAPI(
  companyId: string | undefined,
  shopId: string | undefined,
  payload: UpdateShopName
) {
  return new Promise<string>((resolve, reject) => {
    api
      .put(`api/dashboard/companies/${companyId}/shops/${shopId}/name`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}

export async function addShopAPI(companyId: string | undefined, payload: AddShop) {
  return new Promise<string>((resolve, reject) => {
    api
      .post(`api/dashboard/companies/${companyId}/shops/`, payload)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}

export async function uploadShopImageAPI(companyId: string | undefined, shopId: string | undefined, form: FormData) {
  return new Promise<Photo>((resolve, reject) => {
    api
      .post(`api/dashboard/companies/${companyId}/shops/${shopId}/photos`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60000,
      })
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}

export async function deleteImageAPI(
  companyId: string | undefined,
  shopId: string | undefined,
  photoId: string | undefined
) {
  return new Promise<string>((resolve, reject) => {
    api
      .delete(`api/dashboard/companies/${companyId}/shops/${shopId}/photos/${photoId}`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}
