import React from 'react';
import InputSkeleton from '../Input/skeleton';
import TextAreaSkeleton from '../TextArea/skeleton';
import DropzoneCustomSkeleton from '../DropzoneCustom/skeleton';

export const DashboardSkeleton = () => {
  return (
    <>
      <TextAreaSkeleton />
      <InputSkeleton />
      <InputSkeleton />
      <InputSkeleton />
      <DropzoneCustomSkeleton />
    </>
  );
};
