import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectAuth } from '../../../store/authSlice';
import { Dropdown } from 'react-bootstrap';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import bssLogoExtended from '../../../assets/images/bss-logo-extended.svg';
import bssLogo from '../../../assets/images/bss-logo.svg';
import useCheckMobileResolution from '../../../hook/useCheckMobileResolution';
import './style.css';

export default function HeaderBar() {
  const authState = useSelector(selectAuth);
  const auth = useAuth();
  const isMobile = useCheckMobileResolution();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div style={{ height: '100%', width: '100%', display: 'flex', padding: '0px 32px 0px 32px' }}>
        <img
          alt={isMobile ? 'bssLogo' : 'bssLogoExtended'}
          src={isMobile ? bssLogo : bssLogoExtended}
          style={isMobile ? { width: '40px', cursor: 'pointer' } : { width: '250px', cursor: 'pointer' }}
          onClick={() => navigate(`/`)}
        />
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <Dropdown>
            <Dropdown.Toggle className="custom-dropdown-toggle">{authState.displayName}</Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              <Dropdown.Item
                className="custom-dropdown-item"
                onClick={async () => {
                  const idToken = auth.userData?.id_token;
                  await auth.signOut();
                  await auth.signOutRedirect({
                    id_token_hint: idToken,
                  });
                  dispatch(logout());
                }}
              >
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}
