import React from 'react';
import { CustomCardSkeleton } from '../CustomCard/skeleton';

const element = [{ key: 0 }, { key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }];

export const HomePageSkeleton = () => {
  return (
    <div className="row">
      {element.map((e) => (
        <div key={e.key} className="col-sm-12, col-md-6 col-lg-4" style={{ marginBottom: '15px' }}>
          <CustomCardSkeleton />
        </div>
      ))}
    </div>
  );
};
