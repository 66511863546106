import React from 'react';
import './style.css';
import Skeleton from 'react-loading-skeleton';

export default function DropzoneCustomSkeleton() {
  return (
    <div className="dropzone-container">
      <div className="dropzone-custom-label">
        <Skeleton />
      </div>
      <Skeleton height={150} />
    </div>
  );
}
