import React from 'react';
import useCheckMobileResolution from '../hook/useCheckMobileResolution';
import PageLayout from '../components/Layout/PageLayout';
import { useParams, useSearchParams } from 'react-router-dom';
import { createStripeAccountAPI, uploadStripeAccountLinkUrlAPI } from '../services/companiesApi';
import { toastSuccess, toastWarning } from '../utils/toastMethod';
import { CreateStripeAccountResult, CreateStripeAccountLinkResult } from '../models/Company';
import { useAppSelector } from '../hook/useReduxHooks';
import { RootState } from '../store/store';

export default function ConnectAccountBankPage() {
  const stipeAccountId = useAppSelector((state: RootState) => state.company.stripeAccountId);
  const isMobile = useCheckMobileResolution();
  const { companyId } = useParams<string>();
  const [searchParams] = useSearchParams();
  const accountId =
    searchParams.get('accountId') ?? (stipeAccountId !== null && stipeAccountId.length > 0 ? stipeAccountId : null);

  const handleCreateProfile = () => {
    createStripeAccountAPI(companyId).then((stripeAccount: CreateStripeAccountResult) => {
      uploadStripeAccountLinkUrlAPI(companyId, stripeAccount.accountId, {
        refreshUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/stripe/account?accountId=${stripeAccount.accountId}`,
        returnUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/stripe/account/${stripeAccount.accountId}`,
      })
        .then((redirectUrl: CreateStripeAccountLinkResult) => {
          if (redirectUrl) {
            window.location.href = redirectUrl.url;
          }
        })
        .catch(() =>
          toastWarning("Problemi durante la creazione del profilo attività. Riprova o contatta l'assistenza.")
        );
    });
  };

  const handleCompleteProfile = (id: string) => {
    uploadStripeAccountLinkUrlAPI(companyId, id, {
      refreshUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/stripe/account?accountId=${id}`,
      returnUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/stripe/account/${id}`,
    })
      .then((redirectUrl: CreateStripeAccountLinkResult) => {
        if (redirectUrl) {
          toastSuccess('Stai per essere reindirizzato su Stripe per completare la procedura.');
          window.location.href = redirectUrl.url;
        }
      })
      .catch(() =>
        toastWarning("Problemi durante il completamento del profilo attività. Riprova o contatta l'assistenza.")
      );
  };

  return (
    <PageLayout titlePage="Collega il tuo conto">
      <div
        style={{
          width: '100%',
          backgroundColor: '#F4EBFB',
          borderRadius: '10px',
          border: '1px solid #7000C8',
          padding: '20px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginRight: 'auto' }}>
          <div style={{ fontWeight: '600' }}>Collega il tuo conto per utilizzare BuoniSulSerio</div>
          <div style={{ marginTop: '3px' }}>
            Verrai reindirizzato su Stripe e dovrai inserire le informazioni necessarie per collegare il tuo conto
            corrente.
          </div>
        </div>
        <div
          style={{ marginTop: isMobile ? '20px' : 'auto', marginBottom: 'auto', marginLeft: !isMobile ? '20px' : '' }}
        >
          {accountId === null ? (
            <button type="button" className="btn btn-primary" onClick={handleCreateProfile}>
              Crea profilo attività
            </button>
          ) : (
            <button type="button" className="btn btn-primary" onClick={() => handleCompleteProfile(accountId)}>
              Completa profilo attività
            </button>
          )}
        </div>
      </div>
    </PageLayout>
  );
}
