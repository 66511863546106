import React, { useEffect, useState } from 'react';
import { getCompaniesAPI } from '../services/companiesApi';
import { Company } from '../models/Company';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from '../utils/toastMethod';
import { CustomCard } from '../components/CustomCard';
import { useDispatch } from 'react-redux';
import { logout } from '../store/authSlice';
import { useAuth } from 'oidc-react';
import PageLayout from '../components/Layout/PageLayout';
import PageLayoutSkeleton from '../components/Layout/PageLayout/skeleton';
import { HomePageSkeleton } from '../components/Skeleton/HomePageSkeleton';

export default function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuth();
  const [companies, setCompanies] = useState<Company[] | undefined>();

  useEffect(() => {
    getCompaniesAPI()
      .then((companies: Company[]) => {
        if (companies.length === 1) navigate(`/company/${companies[0].id}/dashboard`);
        else {
          const temp: Company[] = companies.map((c) => {
            if (c.logo === null) c.logo = 'https://freeloop.it/wp-content/uploads/2022/07/03300.png';
            else {
              const currentTimestamp = new Date().getTime();
              c.logo = `${c.logo}?cacheBuster=${currentTimestamp}`;
            }
            return c;
          });
          setCompanies(temp);
        }
      })
      .catch(() => toastWarning('Errore nel carimento delle company. Riprova!'));
  }, [navigate]);

  return (
    <>
      {companies && companies.length === 0 ? (
        <PageLayout titlePage="Non hai un ruolo in nessun negozio 😔">
          <button
            type="button"
            className="btn btn-primary"
            style={{ justifyContent: 'flex-start', width: '100px' }}
            onClick={async () => {
              dispatch(logout());
              const idToken = auth.userData?.id_token;
              await auth.signOut();
              await auth.signOutRedirect({
                id_token_hint: idToken,
              });
            }}
          >
            Log Out
          </button>
        </PageLayout>
      ) : companies && companies.length >= 1 ? (
        <PageLayout titlePage="Seleziona un'attività">
          <div className="row">
            {companies.map((company, index) => (
              <div key={index} className="col-sm-12 col-md-6 col-lg-3" style={{ marginBottom: '10px' }}>
                <CustomCard width="300px" height="300px">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <img
                      style={{ height: '200px', marginBottom: '20px', objectFit: 'cover' }}
                      alt="companyLogo"
                      src={company.logo}
                    />
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          marginRight: 'auto',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            fontWeight: '600',
                            justifyContent: 'flex-start',
                          }}
                        >
                          {company.name}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          {company.role}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ width: '45px' }}
                        onClick={() => {
                          navigate(`/company/${company.id}/dashboard`);
                        }}
                      >
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.81228 11.5966L4.57648 10.3714L7.82045 7.12749H0.219238V5.32706H7.82045L4.57648 2.08842L5.81228 0.857954L11.1816 6.22727L5.81228 11.5966Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </CustomCard>
              </div>
            ))}
          </div>
        </PageLayout>
      ) : (
        <PageLayoutSkeleton>
          <HomePageSkeleton />
        </PageLayoutSkeleton>
      )}
    </>
  );
}
