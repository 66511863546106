import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getStripeAccountOnboardedResultAPI } from '../services/companiesApi';
import { StripeAccountOnboardingResult } from '../models/Company';
import { toastError } from '../utils/toastMethod';
import { DashboardSkeleton } from '../components/Skeleton/DashboardSkeleton';
import PageLayoutSkeleton from '../components/Layout/PageLayout/skeleton';

export default function BankAccountConnectedPage() {
  const navigate = useNavigate();
  const { companyId } = useParams<string>();
  const { accountId } = useParams<string>();

  useEffect(() => {
    getStripeAccountOnboardedResultAPI(companyId, accountId)
      .then((result: StripeAccountOnboardingResult) => {
        if (!result.isOnboarded) {
          navigate(`/company/${companyId}/stripe/account?accountId=${accountId}`);
        }
        navigate(`/company/${companyId}/dashboard?onboarding=true`);
      })
      .catch(() => {
        toastError('Purtroppo qualcosa non è andato a buon fine. Devi ripetere la procedura.');
        navigate(`/company/${companyId}/stripe/account?accountId=${accountId}`);
      });
  }, [companyId, accountId, navigate]);

  return (
    <PageLayoutSkeleton>
      <DashboardSkeleton />
    </PageLayoutSkeleton>
  );
}
