import React, { ChangeEvent } from 'react';
import './style.css';

interface TextAreaProps {
  title: string;
  text: string;
  placeholder: string;
  maxChars?: number;
  handleChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}
export default function TextArea({ title, text, placeholder, maxChars, handleChange }: TextAreaProps) {
  return (
    <div className="textarea-container">
      <label className="textarea-label">{title}</label>
      <textarea
        className="textarea-box"
        value={text === null ? undefined : text}
        onChange={handleChange}
        placeholder={placeholder}
        rows={3}
      />
      {maxChars ? (
        <div className="textarea-max-chars">
          {maxChars - (text !== null ? text.length : maxChars)} caratteri rimanenti
        </div>
      ) : null}
    </div>
  );
}
